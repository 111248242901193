export const options = {
    commercialType: {
        "shop": "Shop",
        "office": "Office",
        "office-space": "Office Space",
        "business-park": "Business Park",
        "data-centre": "Data Centre",
        "shopping-mall": "Shopping Mall",
        "shopping-complex": "Shopping Complex",
        "industrial-space": "Industrial Space",
        "warehouse-godown": "Warehouse / Godown",
    },
    residentialType: {
        "flat": "Flat",
        "apartment": "Apartment",
        "rowhouse": "Rowhouse",
        "villa": "Villa",
        "bunglow": "Bunglow",
        "duplex": "Duplex",
        "penthouse": "Penthouse",
    },

    contactDetails: {
        "booking-office": "Booking Office",
        "chat-with-us": "Chat with us",
        "call-us": "Call us",
        "sales-marketing-partner": "Sales / Marketing Partner",
        "strategic-partner": "Strategic Partner",
        "pre-sales-team": "Pre Sales Team",
        "sales-marketing-team": "Sales / Marketing Team",
        "pan-india-sale-team": "PAN India Sales Team",
        "pan-city-sale-team": "PAN City Sales Team",
        "broker-agent": "Broker / Agent",
        "no-option": "No Option",
    },

    bhk: {
        "1RK-studio": "1 RK Studio Apartment",
        "1BHK": "1 BHK",
        "2BHK": "2 BHK",
        "2.5BHK": "2.5 BHK",
        "3BHK": "3 BHK",
        "3.5BHK": "3.5 BHK",
        "4BHK": "4 BHK",
        "5+BHK": "5+ BHK",
    }
}
