import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Navbar from "./Components/Navbar/Navbar";
import { ProjectDetails } from './Components/Project Details/ProjectDetails';
import axios from 'axios';

const LoginPage = lazy(() => import('./Pages/Login/LoginPage'));
const Dashboard = lazy(() => import('./Pages/Dashboard/Dashboard'));
const Residential = lazy(() => import('./Pages/Residental/ResidentialPage'));
const CommercialPage = lazy(() => import('./Pages/Commercial/CommercialPage'));
const ResidentialForm = lazy(() => import('./Components/Residential Form/ResidentialForm'));
const CommercialForm = lazy(() => import('./Components/Commercial Form/CommercialForm'));
const PlotPage = lazy(() => import('./Pages/Plot/PlotPage'));
const PlotForm = lazy(() => import('./Components/Plot Form/PlotForm'));
const ChangePasswordPage = lazy(() => import('./Pages/Change Password/ChangePasswordPage'));
const NewProjectForm = lazy(() => import('./Components/New Project Form/NewProjectForm'));
const NewProjectPage = lazy(() => import('./Pages/New Project/NewProjectPage'));

function App() {
  const token = localStorage.getItem("token") !== null;

  useEffect(() => {
    const checkAdminLogin = async () => {
      try {
        if (token) {
          const response = await axios.get('https://property-stock-backend.vercel.app/super-admin/advertisement');
          if (!response.data.response.adminLogin) {
            localStorage.removeItem("token");
            alert("You have been Logged Out by Admin.");
            window.location.reload();
          }
        }
      }
      catch (error) {
        alert("Some Issue Occurs, You are Logged Out!");
        localStorage.removeItem("adminToken");
      }
    }
    checkAdminLogin();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<FontAwesomeIcon icon={faSpinner} spin />}>
          <Routes>
            <Route path='/' element={token ? <Dashboard /> : <LoginPage />} />
            <Route path='/dashboard' element={token ? <Dashboard /> : <LoginPage />} />
            <Route path='/change-password' element={token ? <ChangePasswordPage /> : <LoginPage />} />
            <Route path='/residential/:status?' element={token ? <Residential /> : <LoginPage />} />
            <Route path='/residential/add-property/:status?' element={token ? <ResidentialForm /> : <LoginPage />} />
            <Route path='/commercial/:status?' element={token ? <CommercialPage /> : <LoginPage />} />
            <Route path='/commercial/add-property/:status?' element={token ? <CommercialForm /> : <LoginPage />} />
            <Route path='/plot/:status?' element={token ? <PlotPage /> : <LoginPage />} />
            <Route path='/plot/add-property/:status?' element={token ? <PlotForm /> : <LoginPage />} />
            <Route path='/project/add/:status?' element={token ? <NewProjectForm /> : <LoginPage />} />
            <Route path='/project/:status?' element={token ? <NewProjectPage /> : <LoginPage />} />
            <Route path='/project/details/:status' element={token ? <ProjectDetails /> : <LoginPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
