import './NewProjectForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { amenitiesCol1, amenitiesCol2 } from '../../Assests/amenities';
import ArrowUp from "../../Assests/ArrowUp.svg";
import { options } from '../../Assests/options';
import StarColor from "../../Assests/StarColor.svg";
import Star from "../../Assests/Star.svg";
import axios from "axios";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validateProjectForm } from '../../Utils/validateProjectForm';
import imageCompression from 'browser-image-compression';
import Tick from "../../Assests/Tick.svg";
import { useDeleteProperty } from '../../Hooks/useDeleteProperty';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
// import LocationPicker from '../LocationPicker';
// import { NewProjectTable } from "../NewProjectTable/NewProjectTable"

function NewProjectForm() {
    // const [showMap, setShowMap] = useState(false);

    const defaultResidentialRow = { residentialType: "", bhk: "", sqFtSize: "", prices: "" };
    const defaultCommercialRow = { commercialType: "", sqFtSize: "", prices: "" };


    const [initialResidentialTableData, setInitialResidentialTableData] = useState(Array.from({ length: 8 }, () => ({ ...defaultResidentialRow })));
    const [initialCommercialTableData, setInitialCommercialTableData] = useState(Array.from({ length: 8 }, () => ({ ...defaultCommercialRow })));


    const [rera, setRera] = useState(false);
    const [loading, setLoading] = useState(false);
    const { status } = useParams();
    const [formErrors, setFormErrors] = useState({});
    const [editable, setEditable] = useState(false);
    const [uploadedImages, setUploadedImages] = useState({
        projectImages: [],
        bookingOffer: [],
        floorPlans1: [],
        floorPlans2: [],
        floorPlans3: [],
        floorPlans4: [],
    });

    const navigate = useNavigate();
    const location = useLocation();
    const { property } = location.state || {};
    const pathname = location.pathname;

    const [formData, setFormData] = useState({
        residentialDetails: initialResidentialTableData,
        commercialDetails: initialCommercialTableData,
        projectImages: "",
        projectCategory: "residential",
        amenities: [],
        additionalInformation: {
            transportation: "",
            airport: "",
            mall: "",
            school: "",
            railwayStation: "",
            metroStation: "",
            hotel: "",
            restaurant: "",
            foodCourt: "",
            hospitals: "",
            entertainment: ""
        },
        bookingOffer: property?.bookingOffer || "",
        bookingOfferType: "",
        projectDetails: "",
        features: "",
        freeText: "",
        rating: 0,
    });

    const [table, setTable] = useState({});

    // useEffect(() => {
    //     if (property && pathname.includes("/project/details/")) {
    //         setFormData({ ...property });

    //         setFormData((prev) => ({
    //             ...prev,
    //             additionalInformation: property.additionalInformation[0],
    //         }));

    //         if (property?.projectCategory === "residential" && Array.isArray(property.residentialDetails)) {
    //             setInitialResidentialTableData(property.residentialDetails
    //                 .concat(Array(8 - property.residentialDetails.length).fill(defaultResidentialRow))
    //                 .slice(0, 8));
    //         }

    //         if (property?.projectCategory === "commercial" && Array.isArray(property.commercialDetails)) {
    //             setInitialCommercialTableData(property.commercialDetails
    //                 .concat(Array(8 - property.commercialDetails.length).fill(defaultCommercialRow))
    //                 .slice(0, 8));
    //         }

    //         if (!editable) {
    //             setEditable(true);
    //         }
    //     }
    // }, [property, pathname]);

    useEffect(() => {
        if (property && pathname.includes("/project/details/")) {
          // Populate formData from the property data
          setFormData({ ...property });
      
          // Handle the additionalInformation separately if it's an array
          setFormData((prev) => ({
            ...prev,
            additionalInformation: property.additionalInformation[0],
          }));
      
          // Ensure the residential table always has 8 rows
          if (property?.projectCategory === "residential" && Array.isArray(property.residentialDetails)) {
            const updatedResidentialTable = property.residentialDetails
              .concat(Array(8 - property.residentialDetails.length).fill(defaultResidentialRow))
              .slice(0, 8);
            setInitialResidentialTableData(updatedResidentialTable);
            setFormData((prev) => ({
              ...prev,
              residentialDetails: updatedResidentialTable,
            }));
          }
      
          // Ensure the commercial table always has 8 rows
          if (property?.projectCategory === "commercial" && Array.isArray(property.commercialDetails)) {
            const updatedCommercialTable = property.commercialDetails
              .concat(Array(8 - property.commercialDetails.length).fill(defaultCommercialRow))
              .slice(0, 8);
            setInitialCommercialTableData(updatedCommercialTable);
            setFormData((prev) => ({
              ...prev,
              commercialDetails: updatedCommercialTable,
            }));
          }
      
          // Set editable mode if not already set
          if (!editable) {
            setEditable(true);
          }
        }
      }, [property, pathname]);

    const fileInputRef = useRef(null);
    const bookingOfferImageRef = useRef(null);
    const floorPlanRef1 = useRef(null);
    const floorPlanRef2 = useRef(null);
    const floorPlanRef3 = useRef(null);
    const floorPlanRef4 = useRef(null);

    const { handleDeleteProperty } = useDeleteProperty(
        `project/delete/${property?._id}`,
        pathname === "/project/details/approved" ? "/project/approved" : "/project/not-approved"
    );

    const handleImageUpload = (ref) => {
        ref.current.click();
    };

    const handleFileChange = async (event) => {
        const { name } = event.target;
        const file = event.target.files[0];

        if (file && ((file.type.startsWith('image/')) || (file.type.startsWith('video/')))) {
            const options = {
                maxSizeMB: 1,
                useWebWorker: true,
            };

            const compressedFile = file.type.startsWith('image/') ? await imageCompression(file, options) : file;

            setUploadedImages((prev) => ({
                ...prev,
                [name]: compressedFile,
            }));

            if (name === "bookingOffer") {
                setFormData((prev) => ({
                    ...prev,
                    bookingOfferType: file.type.startsWith('image/') ? "image" : file.type.startsWith('video/') ? "video" : null,
                }));
            }
        }
        else {
            alert("Select a valid Image / Video type");
        }
    };

    const uploadImagesToCloudinary = async () => {
        const updatedFormData = { ...formData };

        try {
            for (const key in uploadedImages) {
                const file = uploadedImages[key];

                if (Array.isArray(file) && file.length === 0) {
                    continue;
                }

                console.log(formData.bookingOfferType);
                console.log(key);

                if (formData.bookingOfferType === "video" && file && file[0]?.type.startsWith("video/")) {
                    const file = uploadedImages[key][0];
                    const uploadFormData = new FormData();
                    uploadFormData.append('file', file);
                    uploadFormData.append('upload_preset', 'image_upload');
                    uploadFormData.append('cloud_name', 'djjcged23');

                    try {
                        const response = await axios.post(`https://api.cloudinary.com/v1_1/djjcged23/upload`, uploadFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        const result = response.data;
                        updatedFormData[key] = result.secure_url;
                        console.log(result.secure_url);

                    } catch (error) {
                        console.error(`Error uploading ${key}:`, error);
                    }
                    continue;
                }

                if (file && file.type.startsWith('image/')) {
                    console.log(file);
                    const uploadFormData = new FormData();
                    uploadFormData.append('file', file);
                    uploadFormData.append('upload_preset', 'image_upload');
                    uploadFormData.append('cloud_name', 'djjcged23');

                    try {
                        const response = await axios.post(`https://api.cloudinary.com/v1_1/djjcged23/upload`, uploadFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        const result = response.data;
                        updatedFormData[key] = result.secure_url;
                        console.log(result.secure_url);

                    } catch (error) {
                        console.error(`Error uploading ${key}:`, error);
                    }
                }
            }
            return updatedFormData;
        }
        catch (error) {
            alert("Error While Uploading Images");
            console.error('Error uploading images:', error);
        }
    };

    const handleAddProject = async () => {

        // console.log(formData);
        // return;

        setLoading(true);

        const { errors, cleanedResidentialData, cleanedCommercialData } = validateProjectForm(formData, rera);

        setFormErrors(errors);

        if (Object.keys(errors).length !== 0) {
            setLoading(false);
            alert("Enter valid details.");
            console.log(errors);
            return;
        }

        const updatedProjectData = await uploadImagesToCloudinary();

        if (updatedProjectData.projectCategory === "residential") {
            updatedProjectData.residentialDetails = cleanedResidentialData;
            delete updatedProjectData.commercialDetails;
        }
        else {
            updatedProjectData.commercialDetails = cleanedCommercialData;
            delete updatedProjectData.residentialDetails;
        }

        if (status === "approved") {
            updatedProjectData.approved = true;
        } else {
            updatedProjectData.approved = false;
            delete updatedProjectData.rating;
        }

        try {
            const token = localStorage.getItem("token");
            await axios.post(`https://property-stock-backend.vercel.app/project/add`,
                updatedProjectData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then(res => {
                    alert(res.data.message);
                    window.location.reload();
                    setLoading(false);
                })
                .catch((error) => {
                    alert(error.response.data.message || "Some Issue Occurs");
                    console.log(error.response);
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        localStorage.removeItem("token");
                        alert("Session expired. Please Login again.");
                        window.location.reload();
                    }
                    console.error(error);
                    setLoading(false)
                })
        }
        catch (error) {
            setLoading(false)
            alert("Some Issue Occurs");
            console.error("Error while Adding Project: ", error.message);
        }
    }

    const handleUpdateProject = async () => {
        setLoading(true);
        console.log(formData);
        const { errors, cleanedResidentialData, cleanedCommercialData } = validateProjectForm(formData, rera);

        setFormErrors(errors);

        if (Object.keys(errors).length !== 0) {
            setLoading(false);
            alert("Enter valid details.");
            console.log(errors);
            return;
        }

        const updatedProjectData = await uploadImagesToCloudinary();

        if (updatedProjectData.rating) {
            updatedProjectData.approved = true;
        }

        if (updatedProjectData.projectCategory === "residential") {
            updatedProjectData.residentialDetails = cleanedResidentialData;
            delete updatedProjectData.commercialDetails;
        }
        else {
            updatedProjectData.commercialDetails = cleanedCommercialData;
            delete updatedProjectData.residentialDetails;
        }

        try {
            const token = localStorage.getItem("token");
            await axios.post(`https://property-stock-backend.vercel.app/project/update/${property._id}`,
                { updateData: updatedProjectData },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then(res => {
                    alert(res.data.message);
                    navigate(`/project/${status}`);
                    setLoading(false);
                })
                .catch(error => {
                    alert(error.response.data.message || "Some Issue Occurs");
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        localStorage.removeItem("token");
                        alert("Session expired. Please Login again.");
                        window.location.reload();
                    }
                    console.error(error);
                    setLoading(false)
                })
        }
        catch (error) {
            setLoading(false)
            alert("Some Issue Occurs");
            console.error("Error while Updating Project:");
        }
    }

    const handleDeleteProject = async (event) => {
        handleDeleteProperty(event);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleReraNotApplicable = () => {
        setRera(!rera);
        document.getElementById('reraNumber').value = "";
    };

    const handleAdditionalInfoChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            additionalInformation: {
                ...prevState.additionalInformation,
                [name]: value
            }
        }));
    };

    const handleTableChange = (rowIndex, field, value) => {
        const categoryKey = formData.projectCategory === "commercial" ? "commercialDetails" : "residentialDetails";
        const updatedTableData = [...formData[categoryKey]];
        updatedTableData[rowIndex] = { ...updatedTableData[rowIndex], [field]: value };

        setFormData(prevState => ({
            ...prevState,
            [categoryKey]: updatedTableData
        }));
    };

    const handleRatingClick = (ratingValue) => {
        setFormData({ ...formData, rating: ratingValue });
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;

        setFormData((prev) => {
            const amenities = checked
                ? [...prev.amenities, name]
                : prev.amenities.filter((amenity) => amenity !== name);

            return { ...prev, amenities };
        });
    };

    let FU = formData.projectCategory === "residential" ? "Floors" : "Units";

    console.log(formData);

    return (
        <>
            <div className="project-container">
                <div className="project-details">
                    <div className="left">
                        <div className='input-fields'>
                            <label htmlFor="projectName" id={formErrors.projectName ? "error" : ""}>Project Name <span id='required'>*</span></label>
                            <input
                                type="text"
                                id="projectName"
                                name='projectName'
                                value={formData.projectName}
                                placeholder="Enter Project Name"
                                onChange={handleInputChange}
                                readOnly={editable}
                            />
                        </div>

                        <div className='input-fields'>
                            <label htmlFor="projectAddress" id={formErrors.projectAddress ? "error" : ""}>Project Address <span id='required'>*</span></label>
                            <input
                                type="text"
                                id="projectAddress"
                                value={formData.projectAddress}
                                name='projectAddress'
                                placeholder="Enter Full Address"
                                onChange={handleInputChange}
                                readOnly={editable}
                            />
                        </div>

                        <div className='two-cols'>
                            <div className='input-fields'>
                                <label htmlFor="projectCategory" id={formErrors.projectCategory ? "error" : ""}>Project Category <span id='required'>*</span></label>
                                <select
                                    name="projectCategory"
                                    id="projectCategory"
                                    value={formData.projectCategory}
                                    defaultValue={formData.projectCategory}
                                    onChange={handleInputChange}
                                    disabled={editable}
                                >
                                    <option value="commercial">Commercial</option>
                                    <option value="residential">Residential</option>
                                </select>
                            </div>

                            <div className='input-fields'>
                                <label htmlFor="type" id={formErrors.type ? "error" : ""}>Type <span id='required'>*</span></label>
                                <select
                                    name="type"
                                    id="type"
                                    value={formData.type}
                                    disabled={editable}
                                    onChange={handleInputChange}
                                    defaultValue={formData.projectCategory === "commercial" ? "Office" : "Flat"}
                                >
                                    <option>-- Select Option --</option>
                                    {
                                        formData.projectCategory === "commercial" && (
                                            Object.entries(options.commercialType).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))
                                        )

                                    }
                                    {
                                        formData.projectCategory === "residential" && (
                                            Object.entries(options.residentialType).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))
                                        )
                                    }
                                </select>
                            </div>
                            {formData.projectCategory === "residential" && <div className='input-fields'>
                                <label htmlFor="bhk" id={formErrors.bhk ? "error" : ""}>BHK <span id='required'>*</span></label>
                                <select
                                    name='bhk'
                                    disabled={editable}
                                    value={formData.bhk}
                                    onChange={handleInputChange}
                                    defaultValue="1 RK Studio Apartment"
                                >
                                    <option>-- Select Option --</option>
                                    {

                                        Object.entries(options.bhk).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))
                                    }
                                </select>
                            </div>}
                        </div>

                        <div className='two-cols'>
                            <div className='input-fields'>
                                <label htmlFor="startingPrice" id={formErrors.startingPrice ? "error" : ""}>Starting Price <span id='required'>*</span></label>
                                <input
                                    type="text"
                                    id="startingPrice"
                                    name='startingPrice'
                                    value={formData.startingPrice}
                                    placeholder="Enter Starting Price"
                                    onChange={handleInputChange}
                                    readOnly={editable}
                                />
                            </div>

                            {formData.projectCategory === "commercial" && <div className='input-fields'>
                                <label htmlFor="startingSize" id={formErrors.startingSize ? "error" : ""}>Starting Size (Sq.Ft) <span id='required'>*</span></label>
                                <input
                                    type="text"
                                    name='startingSize'
                                    id="startingSize"
                                    value={formData.startingSize}
                                    placeholder="Enter Starting Size"
                                    onChange={handleInputChange}
                                    readOnly={editable}
                                />
                            </div>}
                        </div>
                    </div>

                    <div className="right">
                        <div className='two-cols'>
                            <div className='input-fields'>
                                <label htmlFor="contactNumber">Contact Number:</label>
                                <input
                                    type="text"
                                    id="contactNumber"
                                    name='contactNumber'
                                    value={formData.contactNumber}
                                    placeholder="Enter Contact Number"
                                    onChange={handleInputChange}
                                    readOnly={editable}
                                />
                            </div>

                            <div className='input-fields'>
                                <label htmlFor="whatsappNumber">Whatsapp Number:</label>
                                <input
                                    type="text"
                                    id="whatsappNumber"
                                    name='whatsappNumber'
                                    value={formData.whatsappNumber}
                                    placeholder="Enter Whatsapp Number"
                                    onChange={handleInputChange}
                                    readOnly={editable}
                                />
                            </div>
                        </div>

                        <select
                            name="contactDetails"
                            id="contactDetails"
                            value={formData.contactDetails}
                            onChange={handleInputChange}
                            disabled={editable}
                        >
                            <option value="" >Select Contact Details</option>
                            {
                                Object.entries(options.contactDetails).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))
                            }
                        </select>

                        <div className='image-container' onClick={() => !editable && handleImageUpload(fileInputRef)}>
                            {(formData.projectImages && uploadedImages.projectImages.length === 0) ? (
                                <img
                                    src={formData.projectImages}
                                    alt="Project Preview"
                                    style={{ width: 'auto', height: '100%' }}
                                />
                            ) : (
                                <div>
                                    {uploadedImages.projectImages.length === 0 ? "Upload Image" : "Image Selected Successfully"}
                                </div>
                            )}
                            <input
                                type='file'
                                name='projectImages'
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                </div>
                <div className="project-summary">
                    <h1>Project Summary </h1>
                    <div className='upper'>
                        <div className='input-fields'>
                            <label htmlFor="projectNumber">Project Number:</label>
                            <input
                                type="text"
                                id="projectNumber"
                                name='projectNumber'
                                value={formData.projectNumber}
                                onChange={handleInputChange}
                                placeholder="Enter Project Number"
                                readOnly={editable}
                            />

                        </div>
                        <div className='input-fields'>
                            <label htmlFor="builderCompanyName" id={formErrors.builderCompanyName ? "error" : ""}>Builder Company Name <span id='required'>*</span></label>
                            <input
                                type="text"
                                id="builderCompanyName"
                                name='builderCompanyName'
                                value={formData.builderCompanyName}
                                onChange={handleInputChange}
                                placeholder="Enter Builder Company Name"
                                readOnly={editable}
                            />
                        </div>
                        <div className='input-fields'>
                            <label htmlFor="ownershipType">Ownership Type:</label>
                            <input
                                type="text"
                                id="ownershipType"
                                name='ownershipType'
                                value={formData.ownershipType}
                                onChange={handleInputChange}
                                readOnly={editable}
                                placeholder="Enter Ownership Type" />
                        </div>
                        <div className='input-fields'>
                            <label htmlFor="reraNumber" id={formErrors.reraNumber ? "error" : ""}>RERA Number {rera ? "" : <span id='required'>*</span>}</label>
                            <input
                                type="text"
                                id="reraNumber"
                                name='reraNumber'
                                value={formData.reraNumber}
                                placeholder={rera ? "RERA Number Not Applicable" : "Enter RERA Number"}
                                disabled={rera}
                                onChange={handleInputChange}
                                readOnly={editable}
                                className={rera ? 'reraNumber-color' : ""}
                            />
                        </div>

                        <div id='not-applicable'>
                            <label htmlFor="checkbox">RERA - Not Applicable</label>
                            <input type="checkbox" id="checkbox" onChange={handleReraNotApplicable} checked={rera} disabled={editable} />
                        </div>
                    </div>

                    <div className='middle'>
                        <div className='input-fields'>
                            <label htmlFor="possessionDate" id={formErrors.possessionDate ? "error" : ""}>Possession Date <span id='required'>*</span></label>
                            <input
                                type="text"
                                id="possessionDate"
                                name='possessionDate'
                                value={formData.possessionDate}
                                onChange={handleInputChange}
                                placeholder="Enter Possession Date"
                                readOnly={editable}
                            />
                        </div>
                        <div className='input-fields'>
                            <label htmlFor="projectLandArea" id={formErrors.possessionDate ? "error" : ""}>Project Land Area <span id='required'>*</span></label>
                            <input
                                type="text"
                                id="projectLandArea"
                                name='projectLandArea'
                                value={formData.projectLandArea}
                                onChange={handleInputChange}
                                readOnly={editable}
                                placeholder="Enter Project Land Area" />
                        </div>
                        <div className='input-fields'>
                            <label htmlFor="units">Total Number of {FU}:</label>
                            <input
                                type="text"
                                id="units"
                                name='units'
                                value={formData.units}
                                readOnly={editable}
                                onChange={handleInputChange}
                                placeholder={"Enter Total Number of " + FU} />
                        </div>
                    </div>

                    <div className='table'>
                        {
                            <table className='data-table'>
                                <thead>
                                    <tr>
                                        <th>
                                            {
                                                formData.projectCategory === "commercial"
                                                    ? <span id={formErrors.commercialDetails ? "error" : ""}>Commercial Type  <span id='required'>*</span></span>
                                                    : <span id={formErrors.residentialDetails ? "error" : ""}>Residential Type  <span id='required'>*</span></span>
                                            }
                                        </th>

                                        {
                                            formData.projectCategory === "residential" &&
                                            <th id={formErrors.residentialDetails ? "error" : ""}>
                                                BHK
                                                <span id='required'>*</span>
                                            </th>
                                        }

                                        <th id={formErrors.commercialDetails
                                            ? "error"
                                            : (formErrors.residentialDetails ? "error" : "")}>Sq.Ft Size <span id='required'>*</span></th>

                                        <th id={formErrors.commercialDetails ? "error" : (formErrors.residentialDetails ? "error" : "")}>Price <span id='required'>*</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(formData.projectCategory === "residential" ? formData.residentialDetails : formData.commercialDetails).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={formData.projectCategory === "commercial" ? row.commercialType : row.residentialType}
                                                    onChange={(e) => handleTableChange(rowIndex, formData.projectCategory === "commercial" ? "commercialType" : "residentialType", e.target.value)}
                                                    readOnly={editable}
                                                    placeholder={formData.projectCategory === "commercial" ? "Type" : "Type"}
                                                />
                                            </td>
                                            {formData.projectCategory === "residential" && <td>
                                                <input
                                                    type="text"
                                                    value={row.bhk}
                                                    readOnly={editable}
                                                    onChange={(e) => handleTableChange(rowIndex, 'bhk', e.target.value)}
                                                    placeholder="BHK"
                                                />
                                            </td>}
                                            <td>
                                                <input
                                                    type="text"
                                                    value={row.sqFtSize}
                                                    readOnly={editable}
                                                    onChange={(e) => handleTableChange(rowIndex, 'sqFtSize', e.target.value)}
                                                    placeholder="Sq.Ft"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={row.prices}
                                                    readOnly={editable}
                                                    onChange={(e) => handleTableChange(rowIndex, 'prices', e.target.value)}
                                                    placeholder="Price"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>

                    {/* Amenities & Booking Offers */}
                    <div className="amenities-booking">
                        <div className="amenities_listing">
                            <h1 className='amenities-heading'>Amenities: </h1>
                            <div className="amenites">
                                <div className="col1">
                                    {
                                        amenitiesCol1.map((data) => (
                                            <div className='amenities-checkbox' key={data}>
                                                <p>{data}</p> <input type="checkbox" name={data} disabled={editable} onChange={handleCheckbox} checked={formData.amenities.includes(data)} />
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col2">
                                    {
                                        amenitiesCol2.map((data) => (
                                            <div className='amenities-checkbox' key={data}>
                                                <p>{data}</p> <input type="checkbox" name={data} disabled={editable} onChange={handleCheckbox} checked={formData.amenities.includes(data)} />
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col3">
                                    <textarea
                                        value={formData.projectDetails || ''}
                                        name='projectDetails'
                                        onChange={handleInputChange}
                                        placeholder='Enter Project Details'
                                        readOnly={editable}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div className="booking-offers">
                            <h1 className='booking-heading'>Booking Offers: </h1>
                            <div className='booking-content'>
                                {(pathname === "/project/details/approved" && uploadedImages.bookingOffer.length === 0)
                                    ? <>
                                    {/* {formData.bookingOfferType === "image" && (  */}
                                        <img
                                            className='booking-offer-image'
                                            src={property.bookingOffer ? property.bookingOffer : null}
                                            alt='booking offer image'
                                            name="bookingOffer"
                                            onClick={() => !editable && handleImageUpload(bookingOfferImageRef)}
                                        />
                                    {/* )} */}

                                    {formData.bookingOfferType === "video" && ( 

                                        <video 
                                        className='booking-offer-image'
                                        controls
                                        name="bookingOffer"
                                        onClick={() => !editable && handleImageUpload(bookingOfferImageRef)}
                                        >
                                            <source src={property.bookingOffer ? property.bookingOffer : null} type="video/mp4"  />
                                        </video>
                                    )}

                                        <input
                                            type='file'
                                            name='bookingOffer'
                                            ref={bookingOfferImageRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileChange(e);
                                                setUploadedImages(prev => ({
                                                    ...prev,
                                                    bookingOffer: [e.target.files[0]]
                                                }));
                                                setFormData(prev => ({
                                                    ...prev,
                                                    bookingOffer: ''
                                                }));
                                            }}
                                            accept="image/*, video/*"
                                        />
                                    </>
                                    : <button onClick={() => handleImageUpload(bookingOfferImageRef)}>
                                        {uploadedImages.bookingOffer.length === 0
                                            ? "Upload Photo / Video"
                                            : <img src={Tick} alt="Booking Offer Selected" />}
                                        <input
                                            type='file'
                                            name='bookingOffer'
                                            ref={bookingOfferImageRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileChange(e);
                                                setUploadedImages(prev => ({
                                                    ...prev,
                                                    bookingOffer: [e.target.files[0]]
                                                }));
                                                setFormData(prev => ({
                                                    ...prev,
                                                    bookingOffer: ''
                                                }));
                                            }}
                                            accept="image/*, video/*"
                                        />
                                    </button>
                                }

                                <p>or</p>

                                <textarea
                                    className='booking-offer-info'
                                    value={formData.bookingOfferType === "text"? formData.bookingOffer : ''}
                                    name='bookingOffer'
                                    onChange={(e) => {
                                        const textValue = e.target.value;
                                        setFormData({ ...formData, bookingOffer: textValue, bookingOfferType: "text" });

                                        if (textValue !== "") {
                                            setUploadedImages(prev => ({
                                                ...prev,
                                                bookingOffer: []
                                            }));

                                            setFormData((prev) => ({
                                                ...prev,
                                                bookingOfferType: "text",
                                            }));
                                        }
                                    }}
                                    placeholder='Booking Offers'
                                    readOnly={editable}
                                    disabled={uploadedImages.bookingOffer.length > 0}
                                >
                                </textarea>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="features">
                    <h1 className='feature-heading'>Features:</h1>
                    <div className="textbox">
                        <textarea
                            name='features'
                            placeholder='Enter Feature'
                            value={formData.features}
                            onChange={handleInputChange}
                            readOnly={editable}
                        ></textarea>
                        <textarea
                            name='freeText'
                            value={formData.freeText}
                            onChange={handleInputChange}
                            readOnly={editable}
                        ></textarea>
                    </div>

                    <div className='features-nearby'>
                        {formData.additionalInformation && (
                            Object.keys(formData.additionalInformation).map((key) => (
                                <div className="input-fields" key={key}>
                                    <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                    <input
                                        type="text"
                                        id={key}
                                        name={key}
                                        value={formData.additionalInformation[key] !== undefined
                                            ? formData.additionalInformation[key]
                                            : ''
                                        }
                                        readOnly={editable}
                                        onChange={handleAdditionalInfoChange}
                                    />
                                </div>
                            ))
                        )}
                    </div>

                    <div className="finance-floorplans">
                        <div className="input-fields">
                            <label htmlFor="bank-finance">Bank / Finance</label>
                            <input name='bankOrFinance' value={formData.bankOrFinance} readOnly={editable} onChange={handleInputChange} type="text" id="bank-finance" />
                        </div>

                        {
                            !editable &&
                            <div className="floor-plan">
                                <label htmlFor="floor-plan">Floor Plans</label>
                                <div className="upload-buttons">
                                    <button onClick={() => floorPlanRef1.current.click()}>
                                        {uploadedImages.floorPlans1.length === 0
                                            ? <img src={ArrowUp} alt="Upload" />
                                            : <img src={Tick} alt="Booking Offer Selected" />
                                        }
                                    </button>
                                    <input
                                        type="file"
                                        ref={floorPlanRef1}
                                        name="floorPlans1"
                                        value={formData.floorPlan1}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 0)}
                                        accept="image/*"
                                    />

                                    <button onClick={() => floorPlanRef2.current.click()}>
                                        {uploadedImages.floorPlans2.length === 0
                                            ? <img src={ArrowUp} alt="Upload" />
                                            : <img src={Tick} alt="Booking Offer Selected" />
                                        }
                                    </button>
                                    <input
                                        type="file"
                                        ref={floorPlanRef2}
                                        name="floorPlans2"
                                        value={formData.floorPlan2}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 1)}
                                        accept="image/*"
                                    />

                                    {formData.projectCategory === "residential" && (
                                        <>
                                            <button onClick={() => floorPlanRef3.current.click()}>
                                                {uploadedImages.floorPlans3.length === 0
                                                    ? <img src={ArrowUp} alt="Upload" />
                                                    : <img src={Tick} alt="Booking Offer Selected" />
                                                }
                                            </button>
                                            <input
                                                type="file"
                                                ref={floorPlanRef3}
                                                name="floorPlans3"
                                                value={formData.floorPlan3}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange(e, 2)}
                                                accept="image/*"
                                            />

                                            <button onClick={() => floorPlanRef4.current.click()}>
                                                {uploadedImages.floorPlans4.length === 0
                                                    ? <img src={ArrowUp} alt="Upload" />
                                                    : <img src={Tick} alt="Booking Offer Selected" />
                                                }
                                            </button>
                                            <input
                                                type="file"
                                                ref={floorPlanRef4}
                                                name="floorPlans4"
                                                value={formData.floorPlan4}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange(e, 3)}
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        }

                        {
                            (pathname === "/project/details/approved" && editable &&
                                (property.floorPlans1 || property.floorPlans2 || property.floorPlans3 || property.floorPlans4)) && (
                                <div className='floor-plan-slideshow'>
                                    <Slide>
                                        {property.floorPlans1 && (
                                            <div className="each-slide-effect">
                                                <div style={{ backgroundImage: `url(${property.floorPlans1})` }}>
                                                </div>
                                            </div>
                                        )}

                                        {property.floorPlans2 && (
                                            <div className="each-slide-effect">
                                                <div style={{ backgroundImage: `url(${property.floorPlans2})` }}>
                                                </div>
                                            </div>
                                        )}

                                        {property.floorPlans3 && (
                                            <div className="each-slide-effect">
                                                <div style={{ backgroundImage: `url(${property.floorPlans3})` }}>
                                                </div>
                                            </div>
                                        )}

                                        {property.floorPlans4 && (
                                            <div className="each-slide-effect">
                                                <div style={{ backgroundImage: `url(${property.floorPlans4})` }}>
                                                </div>
                                            </div>
                                        )}
                                    </Slide>
                                </div>
                            )
                        }

                        <div className="input-fields">
                            {/* <label htmlFor="map-upload">Map</label>
                            <button
                                onClick={() => editable && setShowMap(!showMap)}
                            >
                                {
                                    // showMap ? "Close" : <img src={ArrowUp} alt="Upload" />
                                    showMap ? "Close" : formData.map ? <img src={Tick} alt="location selected" /> : <img src={ArrowUp} alt="Upload" />
                                }
                            </button> */}
                        </div>
                        {/* {showMap && (
                            <div style={{ marginTop: '20px', height: '500px', width: '100%' }}>
                                <LocationPicker showMap={showMap} setShowMap={setShowMap} formData={formData} setFormData={setFormData} />
                            </div>
                        )} */}
                    </div>
                </div>
                <div className="rating">
                    <div className='star-container'>
                        <img src={formData.rating >= 1 ? StarColor : Star} name="1" alt='Rating' onClick={() => !editable && handleRatingClick(1)} />
                        <img src={formData.rating >= 2 ? StarColor : Star} name="2" alt='Rating' onClick={() => !editable && handleRatingClick(2)} />
                        <img src={formData.rating >= 3 ? StarColor : Star} name="3" alt='Rating' onClick={() => !editable && handleRatingClick(3)} />
                        <img src={formData.rating >= 4 ? StarColor : Star} name="4" alt='Rating' onClick={() => !editable && handleRatingClick(4)} />
                        <img src={formData.rating === 5 ? StarColor : Star} name="5" alt='Rating' onClick={() => !editable && handleRatingClick(5)} />
                    </div>
                </div>
                {pathname === "/project/add/approved" && <button
                    className='btn-add'
                    onClick={handleAddProject}
                    disabled={loading}
                >
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Add Project"}
                </button>}

                {/* {
                    pathname === "/project/details/approved" &&
                    <button
                        className='btn-add'
                        onClick={() => {
                            if (editable) {
                                handleUpdateProject();
                            } else {
                                setEditable(editable);
                            }
                        }}
                        disabled={loading}
                        type='button'
                    >
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : editable ? 'Update Details' : "Edit Details"}
                    </button>
                } */}
                {
                    (pathname === "/project/details/approved" || pathname === "/project/details/not-approved") && (
                        <button
                            className='btn-add'
                            onClick={() => {
                                if (!editable) {
                                    handleUpdateProject();
                                } else {
                                    setEditable(!editable);
                                }
                            }}
                            disabled={loading}
                            type='button'
                        >
                            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : !editable ? 'Update Details' : "Edit Details"}
                        </button>
                    )
                }

                {pathname !== "/project/add/approved" && <button
                    className='btn-delete'
                    onClick={handleDeleteProject}
                >
                    Delete
                </button>}
            </div>
        </>
    );
}

export default NewProjectForm;
