import axios from "axios";
import { useState } from "react";

export const useDeleteProperty = (url, page) => {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleDeleteProperty = (event) => {
        event.preventDefault();
        setIsDeleteLoading(true);

        const confirmDelete = window.confirm("Are you sure to delete property?");

        if (!confirmDelete) {
            return;
        }

        const token = localStorage.getItem("token");

        if (!token) {
            alert("You are not logged in.");
            return;
        }

        axios.delete(`https://property-stock-backend.vercel.app/${url}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                setIsDeleteLoading(false);
                alert(res.data.message);
                window.location.replace(page);
            })
            .catch(error => {
                setIsDeleteLoading(false);
                alert("Some Issue Occurs, Try again");
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    alert("Session expired. Please Login again.");
                    window.location.reload();
                }
                console.error(error);
            })
    }
    return { isDeleteLoading, handleDeleteProperty };
};