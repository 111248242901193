import './Navbar.css';
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../Assests/logo.jpg";
import menuIcon from "../../Assests/menu.svg";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handlePasswordChange = () => {
        navigate("/change-password");
        setIsMenuOpen(false);
    };

    const handleLogout = () => {
        if (window.confirm("Do you want to logout?")) {
            localStorage.removeItem('token');
            window.location.reload();
        }
    };

    return (
        <div className='navbar'>
            <div className='logo'>
                <img
                    src={logo}
                    alt="logo"
                    className='website-logo'
                />
            </div>
            <div className="text" onClick={() => window.location.replace("/")}>
                {location.pathname === '/' ? 'Admin Panel' : 'PropertiesStock'}
            </div>
            <div className='empty-space'>
                {token && (
                    <img
                        src={menuIcon}
                        alt="menu icon"
                        onClick={toggleMenu}
                        ref={menuRef}
                    />
                )}
            </div>

            {isMenuOpen && (
                <div className='button-container'>
                    <button className='logout-button' onClick={handleLogout}>
                        Logout
                    </button>
{/*                     <button onClick={handlePasswordChange}>
                        Change Password
                    </button> */}
                </div>
            )}
        </div>
    );
}

export default Navbar;
