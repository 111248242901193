import NewProjectForm from "../New Project Form/NewProjectForm";
import "./ProjectDetails.css";

export const ProjectDetails = ({ property }) => {
    return (
        <>
            <NewProjectForm property={property} />
        </>
    );
};
